import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { SCREEN_SIZE } from '../../lib/Media'
import NoImg from '../icons/NoImg'
import TruncatedText from '../TruncatedText/TruncatedText'
import { GTM_ENV_VAR as GEV } from '../../lib/eventTrackerFunction'
import { useTrackPromoImpression } from '../BubbleCategoryCollection/trackingUtils'
import { IdleQueue } from '../../lib/idlize/IdleQueue'
import { AMP_EVENT_NAME } from '../../lib/amplitude'

const Container = styled.div`
  display: inline-block;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  overflow: hidden;
  background-color: #ffffff;
  flex-shrink: 0;
  position: relative;
  padding: 0.75rem 0.5rem 0 0.5rem;
  width: 100%;
  height: 100%;
  margin: 0 1rem 0.5rem 0rem;

  &:hover {
    box-shadow: 2px 2px 6px 0 rgba(105, 105, 105, 0.2);
    border-radius: 4px;
  }
`

const StyledA = styled.a`
  display: block;
  height: 100%;
  text-decoration: none;
`

const NoImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 9.875rem;

  ${SCREEN_SIZE.From.Tablet} {
    height: 12.875rem;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
`

const CardTitle = styled(TruncatedText)`
  color: #333333;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.25rem;
`

const CardSubtitle = styled.div`
  color: #666666;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0.25rem;
`

const Footer = styled.div`
  margin: 0.5rem 0 1rem 0;
`

const AspectRatioBox = styled.div`
  position: relative;
  &:after {
    display: block;
    content: '';
    padding-bottom: 100%;
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top right;
  }
`

const createImpressionTrackingData = ({
  checkoutAddress,
  position,
  collection,
  promoUrl,
  title,
}) => {
  const { id, imageUrl } = collection
  return {
    refId: `${checkoutAddress?.clientId}-${id}`,
    [GEV.PROMOTION_ID]: `gocery-online | widget | ${title}`,
    [GEV.CREATIVE_NAME]: promoUrl,
    [GEV.PROMOTION_NAME]: imageUrl,
    [GEV.CREATIVE_SLOT]: position,
    isGA4: true,
  }
}

const queue = new IdleQueue()

const PromoCard = ({
  item,
  cardSize,
  checkoutAddress,
  position,
  createObserver: createObserverInfo,
  trackImpressions,
  track,
  impressionType,
  title: swimlaneTitle,
}) => {
  const [isBrokenImage, setIsBrokenImage] = React.useState(false)
  const { imageUrl, title, subTitle, link } = item

  const elementRef = React.useRef()

  const impressionTrackingData = createImpressionTrackingData({
    checkoutAddress,
    position,
    collection: item,
    promoUrl: link,
    title,
  })

  const handleTrackClick = () => {
    if (trackImpressions) {
      trackImpressions({ action: 'abandon' })
    }
    if (title === 'More Weekly Deals') {
      track({
        [GEV.EVENT]: 'promotion_clicked',
        [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.STORE_WEEKLY_ADS_CLICKED,
        [GEV.AMPLITUDE_CTA_SUBLOCATION]: [`${swimlaneTitle}`],
        [GEV.AMPLITUDE_CTA_LOCATION]: 'grocery-online | home',
      })
    } else {
      track({
        [GEV.EVENT]: 'promotion_clicked',
        [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.PROMO_CLICKED,
        [GEV.AMPLITUDE_CTA_LOCATION]: 'grocery-online | home',
        [GEV.AMPLITUDE_CTA_SUBLOCATION]: [`${swimlaneTitle}`],
        [GEV.AMPLITUDE_MESSAGE]: title,
        [GEV.ECOMMERCE]: {
          [GEV.PROMOTION_ID]: `grocery-online | widget | ${title}`,
          [GEV.CREATIVE_NAME]: link,
          [GEV.PROMOTION_NAME]: imageUrl,
          [GEV.CREATIVE_SLOT]: position,
        },
      })
    }
  }

  useTrackPromoImpression({
    queue,
    elementRef,
    checkoutAddress,
    createObserverInfo,
    impressionTrackingData,
    collection: item,
    isCategory: false,
  })
  return (
    <Container
      cardSize={cardSize}
      role="cell"
      ref={elementRef}
      data-refid={`${checkoutAddress?.clientId}-${item.id}`}
      data-impressiontype={impressionType}
    >
      <Link
        href={title === 'More Weekly Deals' ? '/weekly-promotions/' : link}
        passHref
        prefetch={false}
        legacyBehavior
      >
        <StyledA onClick={handleTrackClick}>
          {imageUrl ? (
            <div>
              {!isBrokenImage ? (
                <AspectRatioBox>
                  <Image
                    src={imageUrl}
                    alt={title}
                    onError={() => {
                      setIsBrokenImage(true)
                    }}
                  />
                </AspectRatioBox>
              ) : (
                <NoImgContainer>
                  <NoImg width="50%" />
                </NoImgContainer>
              )}
            </div>
          ) : (
            <NoImgContainer>
              <NoImg width="50%" />
            </NoImgContainer>
          )}

          <Footer>
            <CardTitle clamp={2}>{title}</CardTitle>
            <CardSubtitle>{subTitle}</CardSubtitle>
          </Footer>
        </StyledA>
      </Link>
    </Container>
  )
}

export default PromoCard
