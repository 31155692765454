import React from 'react'
import { SCREEN_SIZE } from '../../lib/Media'
import VirtualCarousel from '../VirtualCarousel'
import styled from 'styled-components'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import { CheckoutAddressConsumer } from '../CheckoutAddressProvider/CheckoutAddressProvider'
import Text from '../Text'
import Link from 'next/link'
import { impressionTypes } from '../../lib/impressionsTracker'
import { EventTrackingConsumer } from '../EventTrackingProvider/EventTrackingProvider'
import PromoCard from './PromoCard'
import { usePromoImpressionTracker } from '../Recipe/recipeUtils'
import { useCheckoutAddressStoreIdUpdate } from '../BubbleCategoryCollection/trackingUtils'
import {
  getNCheckFn as getNCheck,
  GTM_ENV_VAR as GEV,
} from '../../lib/eventTrackerFunction'
import { getConfigFor } from '../../utils/configService'
import { SPLIT_FEATURES } from '../../utils/constants'
import { AMP_EVENT_NAME } from '../../lib/amplitude'

const PromoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0 1.8125rem 0;
`

const HeadingDiv = styled.div`
  margin-bottom: 1rem;
  display: flex;
  position: relative;
`

const SubHeader = styled(Text)`
  font-size: 1rem;
  ${SCREEN_SIZE.Below.Tablet} {
    font-size: 0.875rem;
  }
`

const StyledLink = styled(Text)`
  text-decoration: none;
  margin-left: auto;
  position: absolute;
  right: 0;
  bottom: 0;
`

const PageHeading = styled.h2`
  font-weight: 700;
  color: #333333;
  font-size: 1.25rem;
  line-height: 1.4rem;
  ${SCREEN_SIZE.Below.Tablet} {
    font-size: 1rem;
  }
`

const PromoCardWrapper = styled.div`
  width: 11rem;
  height: 16rem;

  ${SCREEN_SIZE.From.MobileL} {
    width: 14rem;
    height: 19rem;
  }
`

const PromoCollection = props => {
  const { data, config, checkoutAddress, track, trackSplit } = props
  const title = data && data.title
  const collections = data && data.collection
  const { subtitle, showProductCount } = config || {}
  const { createObserver, trackImpressions } = usePromoImpressionTracker({
    track,
    trackSplit,
    checkoutAddress,
    impressionType: impressionTypes.PROMO_IMPRESSION,
    meta: {},
  })

  useCheckoutAddressStoreIdUpdate(prevCheckoutAddress => {
    trackImpressions({
      action: 'trackOnUpdate',
      prevStoreId: getNCheck(prevCheckoutAddress, 'clientId', '').toString(),
      prevDBPStoreId: getNCheck(prevCheckoutAddress, 'storeId', '').toString(),
    })
  })

  const handleTrackClick = () => {
    const { isEnabled, configuration } =
      getConfigFor(SPLIT_FEATURES.FE_SEGMENT_PROMO_MAIN_CLICKED) || {}
    const isSegmentEvent = isEnabled && configuration?.is_segment_on

    track({
      [GEV.EVENT]: 'promotion_main_clicked',
      [GEV.AMPLITUDE_CTA_LOCATION]: {
        pageName: 'home',
      },
      [GEV.AMPLITUDE_CTA_SUBLOCATION]: [title],
      [GEV.AMPLITUDE_EVENT_NAME]: AMP_EVENT_NAME.PROMO_MAIN_CLICKED,
      [GEV.IS_SEGEMENT_EVENT]: isSegmentEvent,
    })
  }

  return !(collections && collections.length) ? null : (
    <PromoContainer>
      {(title || subtitle) && (
        <HeadingDiv>
          <div>
            {title && (
              <PageHeading
                size="large"
                weight="bold"
                color={defaultTheme.ColorBaseMineShaft700}
                title={title}
              >
                {title}
              </PageHeading>
            )}
            {subtitle && (
              <SubHeader
                as="h3"
                color={defaultTheme.ColorBaseMineShaft500}
                size="large"
              >
                {subtitle}
              </SubHeader>
            )}
          </div>
          <Link
            href='/weekly-promotions/'
            passHref
            prefetch={false}
            legacyBehavior
          >
            <StyledLink
              as="a"
              size="medium"
              color={defaultTheme.ColorBaseBrandPrimary500}
              weight="bold"
              onClick={handleTrackClick}
            >
              See all
            </StyledLink>
          </Link>
        </HeadingDiv>
      )}
      <VirtualCarousel
        items={collections}
        staticHeight={{
          [SCREEN_SIZE.From.MobileL]: 306,
          [SCREEN_SIZE.Below.MobileL]: 256,
        }}
        staticWidth={{
          [SCREEN_SIZE.From.MobileL]: 240,
          [SCREEN_SIZE.Below.MobileL]: 192,
        }}
      >
        {({ item, index }) => {
          return (
            <PromoCardWrapper>
              <PromoCard
                cardSize={config.subcategories}
                key={'promo-' + index}
                item={item}
                showProductCount={showProductCount}
                bannerPosition={index + 1}
                impressionType={impressionTypes.PROMO_IMPRESSION}
                checkoutAddress={checkoutAddress}
                role="row"
                title={title}
                ctaLocation={config?.ctaLocation}
                ctaSublocation={config?.ctaSublocation}
                createObserver={createObserver}
                trackImpressions={trackImpressions}
                position={index + 1}
                track={track}
              />
            </PromoCardWrapper>
          )
        }}
      </VirtualCarousel>
    </PromoContainer>
  )
}

const PromoCollectionWrap = props => (
  <CheckoutAddressConsumer>
    {({ checkoutAddress }) => (
      <EventTrackingConsumer>
        {({ track, trackSplit }) => (
          <React.Fragment>
            <div />
            {/* Needed for avoiding layout shifting in the home page */}
            <PromoCollection
              {...props}
              track={track}
              trackSplit={trackSplit}
              checkoutAddress={checkoutAddress}
            />
          </React.Fragment>
        )}
      </EventTrackingConsumer>
    )}
  </CheckoutAddressConsumer>
)

PromoCollectionWrap.defaultProps = {
  config: {},
}

export default PromoCollectionWrap
